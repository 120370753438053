<template>
  <AuthWrapper>
    <div
      class="auth-contents"
      style="padding-top:150px"
    >
      <a-form
        layout="vertical"
        :model="login_form_manager.state"
        @finish="login_form_manager.submit"
      >
        <sdHeading as="h3">
          Oturum <span class="color-secondary">Açınız</span>
        </sdHeading>
        <a-form-item
          id="email"
          label="E-Posta"
        >
          <a-input
            v-model:value="login_form_manager.state.email"
            type="email"
          />
        </a-form-item>
        <a-form-item
          id="password"
          label="Şifre"
        >
          <a-input
            v-model:value="login_form_manager.state.password"
            type="password"
            placeholder="Password"
          />
        </a-form-item>
        <div class="auth-form-action">
          <a
            class="forgot-pass-link"
            @click="forgot_password_manager.visible = true"
          >
            Şifremi unuttum
          </a>
        </div>
        <a-form-item>
          <sdButton
            class="btn-signin"
            html-type="submit"
            type="primary"
            size="large"
          >
            {{ login_form_manager.submitting ? 'Lütfen bekleyiniz...' : 'Oturum Aç' }}
          </sdButton>
        </a-form-item>
      </a-form>
    </div>
  </AuthWrapper>

  <a-modal
    v-model:visible="forgot_password_manager.visible"
    title="Şifremi Unuttum"
    :confirm-loading="forgot_password_manager.submitting"
    ok-text="Gönder"
    cancel-text="Kapat"
    @ok="forgot_password_manager.submit"
    @cancel="forgot_password_manager.visible = false"
  >
    <a-form layout="vertical">
      <a-form-item label="E-Posta">
        <a-input
          v-model:value="forgot_password_manager.state.email"
        />
      </a-form-item>
    </a-form>
  </a-modal>

  <a-modal
    v-model:visible="set_new_password_manager.visible"
    title="Yeni Şifre Belirle"
    :confirm-loading="set_new_password_manager.submitting"
    ok-text="Gönder"
    cancel-text="Kapat"
    @ok="set_new_password_manager.submit"
    @cancel="set_new_password_manager.visible = false"
  >
    <a-form-item>
      <a-alert
        type="info"
        style="text-align: center; font-weight: bold;"
        message="E-posta kutunuzu kontrol ediniz, kodu girerek yeni şifrenizi belirleyiniz."
      />
    </a-form-item>
    <a-form layout="vertical">
      <a-form-item
        id="code"
        label="Kod"
      >
        <a-input
          id="code"
          v-model:value="set_new_password_manager.state.code"
        />
      </a-form-item>
      <a-form-item
        id="password"
        label="Şifre"
      >
        <a-input-password
          id="password"
          v-model:value="set_new_password_manager.state.password"
        />
      </a-form-item>
      <a-form-item
        id="password_again"
        label="Şifre Tekrar"
      >
        <a-input-password
          id="password_again"
          v-model:value="set_new_password_manager.state.password_again"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { AuthWrapper } from './style'
import { defineComponent, reactive } from 'vue'
import restMethods from '@/utility/restMethods'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'Login',
  components: {
    AuthWrapper,
  },
  setup() {

    const router = useRouter()

    const login_form_manager = reactive({
      submitting: false,
      state: {
        email: '',
        password: '',
      },
      submit: () => {
        login_form_manager.submitting = true
        restMethods.postForNoc('/login', login_form_manager.state).then(response => {
          if ( ! response.hasError()) {
            localStorage.setItem('jwtToken', response.getData().token)
            router.push({ name: 'Index.Page' })
          } else {
            login_form_manager.submitting = false
          }
        })
      },
    })

    const forgot_password_manager = reactive({
      visible: false,
      submitting: false,
      state: {
        email: '',
      },
      submit: () => {
        forgot_password_manager.submitting = true
        restMethods.postForNoc('/forgot-password', forgot_password_manager.state).then(response => {
          if ( ! response.hasError()) {

            forgot_password_manager.state.email = ''

            forgot_password_manager.visible = false

            set_new_password_manager.state.token = response.getData().token
            set_new_password_manager.visible = true

          }
          forgot_password_manager.submitting = false
        })
      },
    })

    const set_new_password_manager = reactive({
      visible: false,
      submitting: false,
      state: {
        token: '',
        code: '',
        password: '',
        password_again: '',
      },
      submit: () => {
        set_new_password_manager.submitting = true
        restMethods.postForNoc('/set-new-password', set_new_password_manager.state, { show_success: true }).then(response => {
          if ( ! response.hasError()) {

            set_new_password_manager.state.token = ''
            set_new_password_manager.state.code = ''

            set_new_password_manager.visible = false

          }
          set_new_password_manager.submitting = false
        })
      },
    })

    return {
      login_form_manager,
      forgot_password_manager,
      set_new_password_manager,
    }

  },
})

</script>